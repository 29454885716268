import * as React from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider';

import Footer from "../components/footer.js"
import GdprPanel from '../components/gdpr.js'
import Navigation from '../components/navigationVideoPage'
import NewsletterSignup from "../components/newsletterSignUp.js";

// markup
const IndexPage = () => {

  return (      
    <>  
    <SSRProvider>
        <link href="https://use.fontawesome.com/releases/v5.0.2/css/all.css" rel="stylesheet" />
        <Navigation />
        <div id="body" style={{ marginTop: 104 }}>
            <Container fluid="lg">
              <Row className="py-lg-4 py-3 white-bg">
                  <Col className="px-4 text-end">
                    <div id="error" className="error-404">
                        <div>
                            <h2 className="error">404</h2>
                            <h2><strong>ARTICLE NOT FOUND</strong></h2><br/>
                            <h2>Not all those who wander are lost</h2>
                            <p style={{ marginBottom: 0 + "px" }}>Looks like the page you're trying to visit doesn't exist.</p>
                            <p>Please check the URL and try again.</p>
                            <Link to="/" className="button btn btn-primary">Go To Home</Link>
                        </div>
                    </div>
                  </Col>
              </Row>
            </Container>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
)
}


export default IndexPage
